body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fg-Spinner {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Full padding */
.padding--default {
    padding: 8px 16px;
}
.padding--small {
    padding: 4px 8px;
}
.padding--large {
    padding: 16px 32px;
}

/* Horizontal padding */
.padding_horizontal {
    padding-left: 16px;
    padding-right: 16px;
}
.padding_horizontal--x-small {
    padding-left: 4px;
    padding-right: 4px;
}
.padding_horizontal--small {
    padding-left: 8px;
    padding-right: 8px;
}
.padding_horizontal--large {
    padding-left: 32px;
    padding-right: 32px;
}

/* Vertical padding */
.padding_vertical {
    padding-top: 16px;
    padding-bottom: 16px;
}
.padding_vertical--x-small {
    padding-top: 4px;
    padding-bottom: 4px;
}
.padding_vertical--small {
    padding-top: 8px;
    padding-bottom: 8px;
}
.padding_vertical--large {
    padding-top: 32px;
    padding-bottom: 32px;
}

/* Full margin */
.margin--default {
    margin: 8px 16px;
}
.margin--small {
    margin: 4px 8px;
}
.margin--large {
    margin: 16px 32px;
}

/* Horizontal margin */
.margin_horizontal {
    margin-left: 16px;
    margin-right: 16px;
}
.margin_horizontal--x-small {
    margin-left: 4px;
    margin-right: 4px;
}
.margin_horizontal--small {
    margin-left: 8px;
    margin-right: 8px;
}
.margin_horizontal--large {
    margin-left: 32px;
    margin-right: 32px;
}

/* Vertical margin */
.margin_vertical {
    margin-top: 16px;
    margin-bottom: 16px;
}
.margin_vertical--x-small {
    margin-top: 4px;
    margin-bottom: 4px;
}
.margin_vertical--small {
    margin-top: 8px;
    margin-bottom: 8px;
}
.margin_vertical--large {
    margin-top: 32px;
    margin-bottom: 32px;
}

/* Pressable */
.pressable {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.pressable--white {
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #fff;
}
.pressable:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.pressable:active {
    background-color: rgba(0, 0, 0, 0.2);
}

.pressable--white:hover {
    background-color: #d7d7d6;
}
.pressable--light:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.pressable--light:active {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Selected highlight */

.selected {
    background-color: #d9eafa;
}
.selected--highlight {
    background-color: #ecf6ff !important;
}

/* Shortened text */
.shorten-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* ScrollView */
.scrollable {
    overflow-y: scroll;
}

/* Hide */
.hidden {
    display: none;
}

/* Hoverable */
.hoverable .reveal--on-hover {
    opacity: 0;
}
.hoverable:hover .reveal--on-hover {
    opacity: 1;
}

.hoverable > .display--on-parent-hover {
    display: none;
}
.hoverable:hover > .display--on-parent-hover {
    display: inherit;
}

.hoverable > .reveal--on-parent-hover {
    opacity: 0;
}
.hoverable:hover > .reveal--on-parent-hover {
    opacity: 1;
}

/* Spacer */
.spacer--on-top::before {
    margin-top: 16px !important;
}

/* Surface */
.surface {
    background-color: #fff;
    border-radius: 2px;
}

/* Full Page */
.fullpage {
    width: 100vw;
    height: 100vh;
}

/* Brand Icons */
.brand-icon_wrap {
    width: 128px;
    height: 128px;
    border-radius: 64px;
    background-color: #38bff3;
    background-image: linear-gradient(113deg, #38bff3, #38bff3 50%, #00a1de 0, #00a1de 100%, #fff);
}

.ctx-menu {
    padding: 8px 16px;
}

.ctx-menu:active {
    border: 0 !important;
}

.react-contextmenu-item--selected {
    border: 0;
    outline: none;
}

/* Full Opacity on hover */

.full-opacity--on-hover:hover {
    opacity: 1 !important;
}

.introjs-tooltip-settings {
    background-image: url(/static/media/video-icons.7c5506d9.svg);
    background-repeat: no-repeat, repeat;
    background-position: 19px 19px;
    background-size: 70px;
}

.introjs-tooltip-video {
    background-image: url(/static/media/comm-icons.6e239614.svg);
    background-repeat: no-repeat, repeat;
    background-position: 19px 19px;
    background-size: 70px;
}

.introjs-tooltip-start {
    background-image: url(/static/media/colleagues.f219f84f.svg);
    background-repeat: no-repeat, repeat;
    background-position: 25px 180px;
    background-size: 90px;
}

.introjs-tooltip-areas {
    background-image: url(/static/media/chck-chck-chck.5f96cfa0.svg);
    background-repeat: no-repeat, repeat;
    background-position: 187px 190px;
    background-size: 30px;
}
.introjs-tooltip-dude {
    background-image: url(/static/media/flag-dude.14b038b1.svg);
    background-repeat: no-repeat, repeat;
    background-position: 17px 221px;
    background-size: 35px;
}

.introjs-tooltip-thumb {
    background-image: url(/static/media/pollice.24446328.svg);
    background-repeat: no-repeat, repeat;
    background-position: 21px 15px;
    background-size: 30px;
}

.introjs-tooltip-target {
    background-image: url(/static/media/bersaglio.122005ad.svg);
    background-repeat: no-repeat, repeat;
    background-position: 21px 15px;
    background-size: 50px;
}

.introjs-tooltipbuttons {
    border-top: none;
    padding: 10px;
    text-align: right;
    white-space: nowrap;
}

.introjs-button {
    padding: 0.5rem 1rem;
    border: 1px solid #bdbdbd;
    text-decoration: none;
    text-shadow: none;
    font-size: 14px;
    color: #424242;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background-color: #f4f4f4;
    border-radius: 0.2em;
    zoom: 1;
    *display: inline;
}

.introjs-nextbutton {
    background-color: #ff8900;
    color: white;
    width: 75px;
    text-align: center;
    padding: 9px 0px;
    outline-color: grey;
}

.introjs-nextbutton:hover {
    background-color: #ffa02f;
    color: white;
}

.introjs-nextbutton:focus {
    background-color: #ff8900;
    outline: none;
    text-decoration: none;
    box-shadow: white;
    border: 1px solid #616161;
    color: white;
}

.pivot--header {
    font-size: 40px;
    color: red;
}

.roomlist_room_symbol-wrapper {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
}

.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: 30;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}
.react-resizable-handle-w {
    left: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
.react-resizable-handle-e {
    right: 0;
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 0;
    width: 100%;
    cursor: ns-resize;
}
.react-resizable-handle-n {
    top: 0;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
}
.react-resizable-handle-s {
    bottom: 0;
    background-image: none;
    border-bottom: 1px solid grey;
}

