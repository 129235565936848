.introjs-tooltip-settings {
    background-image: url(../media/img/video-icons.svg);
    background-repeat: no-repeat, repeat;
    background-position: 19px 19px;
    background-size: 70px;
}

.introjs-tooltip-video {
    background-image: url(../media/img/comm-icons.svg);
    background-repeat: no-repeat, repeat;
    background-position: 19px 19px;
    background-size: 70px;
}

.introjs-tooltip-start {
    background-image: url(../media/img/colleagues.svg);
    background-repeat: no-repeat, repeat;
    background-position: 25px 180px;
    background-size: 90px;
}

.introjs-tooltip-areas {
    background-image: url(../media/img/chck-chck-chck.svg);
    background-repeat: no-repeat, repeat;
    background-position: 187px 190px;
    background-size: 30px;
}
.introjs-tooltip-dude {
    background-image: url(../media/img/flag-dude.svg);
    background-repeat: no-repeat, repeat;
    background-position: 17px 221px;
    background-size: 35px;
}

.introjs-tooltip-thumb {
    background-image: url(../media/img/pollice.svg);
    background-repeat: no-repeat, repeat;
    background-position: 21px 15px;
    background-size: 30px;
}

.introjs-tooltip-target {
    background-image: url(../media/img/bersaglio.svg);
    background-repeat: no-repeat, repeat;
    background-position: 21px 15px;
    background-size: 50px;
}

.introjs-tooltipbuttons {
    border-top: none;
    padding: 10px;
    text-align: right;
    white-space: nowrap;
}

.introjs-button {
    padding: 0.5rem 1rem;
    border: 1px solid #bdbdbd;
    text-decoration: none;
    text-shadow: none;
    font-size: 14px;
    color: #424242;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background-color: #f4f4f4;
    border-radius: 0.2em;
    zoom: 1;
    *display: inline;
}

.introjs-nextbutton {
    background-color: #ff8900;
    color: white;
    width: 75px;
    text-align: center;
    padding: 9px 0px;
    outline-color: grey;
}

.introjs-nextbutton:hover {
    background-color: #ffa02f;
    color: white;
}

.introjs-nextbutton:focus {
    background-color: #ff8900;
    outline: none;
    text-decoration: none;
    -webkit-box-shadow: white;
    box-shadow: white;
    border: 1px solid #616161;
    color: white;
}
