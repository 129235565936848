/* Full padding */
.padding--default {
    padding: 8px 16px;
}
.padding--small {
    padding: 4px 8px;
}
.padding--large {
    padding: 16px 32px;
}

/* Horizontal padding */
.padding_horizontal {
    padding-left: 16px;
    padding-right: 16px;
}
.padding_horizontal--x-small {
    padding-left: 4px;
    padding-right: 4px;
}
.padding_horizontal--small {
    padding-left: 8px;
    padding-right: 8px;
}
.padding_horizontal--large {
    padding-left: 32px;
    padding-right: 32px;
}

/* Vertical padding */
.padding_vertical {
    padding-top: 16px;
    padding-bottom: 16px;
}
.padding_vertical--x-small {
    padding-top: 4px;
    padding-bottom: 4px;
}
.padding_vertical--small {
    padding-top: 8px;
    padding-bottom: 8px;
}
.padding_vertical--large {
    padding-top: 32px;
    padding-bottom: 32px;
}

/* Full margin */
.margin--default {
    margin: 8px 16px;
}
.margin--small {
    margin: 4px 8px;
}
.margin--large {
    margin: 16px 32px;
}

/* Horizontal margin */
.margin_horizontal {
    margin-left: 16px;
    margin-right: 16px;
}
.margin_horizontal--x-small {
    margin-left: 4px;
    margin-right: 4px;
}
.margin_horizontal--small {
    margin-left: 8px;
    margin-right: 8px;
}
.margin_horizontal--large {
    margin-left: 32px;
    margin-right: 32px;
}

/* Vertical margin */
.margin_vertical {
    margin-top: 16px;
    margin-bottom: 16px;
}
.margin_vertical--x-small {
    margin-top: 4px;
    margin-bottom: 4px;
}
.margin_vertical--small {
    margin-top: 8px;
    margin-bottom: 8px;
}
.margin_vertical--large {
    margin-top: 32px;
    margin-bottom: 32px;
}

/* Pressable */
.pressable {
    cursor: pointer;
    user-select: none;
}
.pressable--white {
    cursor: pointer;
    user-select: none;
    background-color: #fff;
}
.pressable:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.pressable:active {
    background-color: rgba(0, 0, 0, 0.2);
}

.pressable--white:hover {
    background-color: #d7d7d6;
}
.pressable--light:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.pressable--light:active {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Selected highlight */

.selected {
    background-color: #d9eafa;
}
.selected--highlight {
    background-color: #ecf6ff !important;
}

/* Shortened text */
.shorten-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* ScrollView */
.scrollable {
    overflow-y: scroll;
}

/* Hide */
.hidden {
    display: none;
}

/* Hoverable */
.hoverable .reveal--on-hover {
    opacity: 0;
}
.hoverable:hover .reveal--on-hover {
    opacity: 1;
}

.hoverable > .display--on-parent-hover {
    display: none;
}
.hoverable:hover > .display--on-parent-hover {
    display: inherit;
}

.hoverable > .reveal--on-parent-hover {
    opacity: 0;
}
.hoverable:hover > .reveal--on-parent-hover {
    opacity: 1;
}

/* Spacer */
.spacer--on-top::before {
    margin-top: 16px !important;
}

/* Surface */
.surface {
    background-color: #fff;
    border-radius: 2px;
}

/* Full Page */
.fullpage {
    width: 100vw;
    height: 100vh;
}

/* Brand Icons */
.brand-icon_wrap {
    width: 128px;
    height: 128px;
    border-radius: 64px;
    background-color: #38bff3;
    background-image: linear-gradient(113deg, #38bff3, #38bff3 50%, #00a1de 0, #00a1de 100%, #fff);
}

.ctx-menu {
    padding: 8px 16px;
}

.ctx-menu:active {
    border: 0 !important;
}

.react-contextmenu-item--selected {
    border: 0;
    outline: none;
}

/* Full Opacity on hover */

.full-opacity--on-hover:hover {
    opacity: 1 !important;
}
